<template>
  <div class="dd-container home">
    <Banner />
    <News />
  </div>
</template>

<script>
import Banner from '@pc/home/Banner'
import News from '@pc/home/News'

export default {
  components: {
    Banner,
    News
  },
  async fetch ({ store, app }) {
    await store.dispatch('home/getHomeData', app)
  }
}
</script>

<style scoped>
.home {
  margin-top: 50px;
}
</style>
