<template>
  <div
    class="banner-container"
    v-if="bannerList.length"
  >
    <div v-swiper="swiperOption">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(banner, index) in bannerList"
          :key="banner.id"
          :data-my-index="index"
        >
          <div
            class="banner-item"
            :style="{
              background:
                'url(\'' + banner.webImg + '\') center/cover no-repeat',
              cursor: banner.type ? 'pointer' : 'auto'
            }"
          ></div>
        </div>
      </div>

      <div class="custom-wrap animate__animated animate__fadeIn">
        <svg
          class="icon button-prev button"
          aria-hidden="true"
        >
          <use xlink:href="#icon-zuo"></use>
        </svg>
        <div class="pagination"></div>
        <svg
          class="icon button-next button"
          aria-hidden="true"
        >
          <use xlink:href="#icon-you"></use>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'

if (process.client) {
  const VueAwesomeSwiper = require('vue-awesome-swiper')
  Vue.use(VueAwesomeSwiper)
}
let vm = null
export default {
  computed: {
    ...mapState('home', ['bannerList'])
  },
  watch: {
    bannerList: {
      immediate: true,
      deep: true,
      handler (newValue, oldValue) {
        this.swiperOption = {
          threshold: 10,
          speed: 400,
          loop: true,
          direction: 'horizontal',
          paginationClickable: true,
          autoplay: this.bannerList && this.bannerList.length > 1 ? {
            delay: 5000,
            disableOnInteraction: false
          } : false,
          pagination: {
            el: '.pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.button-next',
            prevEl: '.button-prev'
          },
          on: {
            click: function () {
              if (this.clickedSlide && this.clickedSlide.dataset) {
                const realIndex = Number(this.clickedSlide.dataset.myIndex)
                vm.handleClickSlide(realIndex)
              }
            }
          }
        }
      }
    }
  },
  data () {
    return {
      swiperOption: {
        threshold: 10,
        speed: 400,
        loop: true,
        direction: 'horizontal',
        paginationClickable: true,
        autoplay: this.bannerList && this.bannerList.length > 1 ? {
          delay: 5000,
          disableOnInteraction: false
        } : false,
        pagination: {
          el: '.pagination',
          clickable: true
        },
        navigation: {
          nextEl: '.button-next',
          prevEl: '.button-prev'
        },
        on: {
          click: function () {
            if (this.clickedSlide && this.clickedSlide.dataset) {
              const realIndex = Number(this.clickedSlide.dataset.myIndex)
              vm.handleClickSlide(realIndex)
            }
          }
        }
      }
    }
  },
  created () {
    vm = this
  },
  methods: {
    handleClickSlide (reindex) {
      const item = this.bannerList.find(
        (item, itemIndex) => itemIndex === reindex
      )
      if (!(item && item.webImg && item.type)) return

      // 外链
      this.$openWin(item.linkUrl, !item.linkUrl.includes(location.host))
    }
  }
}
</script>

<style lang="scss" scoped>
.banner-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 616px;
  color: #fff;

  .custom-wrap {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 24px;

    .button {
      cursor: pointer;
    }
  }

  ::v-deep .swiper-container {
    height: 100%;

    .swiper-pagination-bullets {
      display: flex;
      padding: 0 30px;
      height: 12px;
    }

    .swiper-pagination-bullet {
      margin: 0 4px;
      width: 12px;
      height: 12px;
      border: 1px solid #5e5e5e;
      border-radius: 50%;
      background: rgba(255, 255, 255, 1);
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background: #5e5e5e;
    }
  }

  .banner-item {
    height: 100%;
  }
}
</style>
