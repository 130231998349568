<template>
  <nuxt-link
    v-if="item && item.id"
    class="news-item"
    :to="localePath(url)"
    @mouseenter.native="handleNewsMouseenter"
    @mouseleave.native="handleNewsMouseleave"
  >
    <img
      class="news-item-img animate__animated animate__fadeIn"
      v-show="isShowImg"
      :src="item.img"
    >

    <p class="news-title">{{item.name}}</p>

    <p class="news-content">{{item.content}}</p>

    <p class="news-date">{{item.startDate}}-{{item.endDate}}</p>
  </nuxt-link>
</template>

<script>
export default {
  data () {
    return {
      isShowImg: false
    }
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  computed: {
    url () {
      const { id } = this.item || {}
      return id ? `/news/${id}` : ''
    }
  },
  methods: {
    handleNewsMouseenter () {
      this.isShowImg = true
    },

    handleNewsMouseleave () {
      this.isShowImg = false
    }
  }
}
</script>

<style lang="scss" scoped>
.news-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 18px 60px 20px;
  width: 33.3%;
  text-align: center;
  word-break: break-all;

  .news-item-img {
    position: absolute;
    top: 0;
    left: 3px;
    width: calc(100% - 6px);
    height: 100%;

    object-fit: cover;
  }

  .news-title {
    color: #000;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;

    @include clamps-text-overflow(2);
  }

  .news-content {
    color: #9d9d9d;
    font-size: 14px;
    line-height: 24px;

    @include clamps-text-overflow(3);
  }

  .news-date {
    color: #000;
    font-size: 14px;
  }
}
</style>
