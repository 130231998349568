<template>
  <div
    class="news-container"
    v-if="newsList.length"
  >
    <div class="hline">
      <p class="title">{{$t('preNews')}}</p>
      <nuxt-link
        class="readmore"
        :to="localePath('/news')"
      >
        <p>{{$t('readMore')}}</p>
        <svg
          class="icon"
          aria-hidden="true"
        >
          <use xlink:href="#icon-you"></use>
        </svg>
      </nuxt-link>
    </div>

    <div class="news">
      <div class="news-vline"></div>

      <NewsItem :item="newsList[0]" />

      <div class="news-vline"></div>

      <NewsItem
        v-if="newsList[1]"
        :item="newsList[1]"
      />

      <div
        class="news-vline"
        v-if="newsList[1]"
      ></div>

      <NewsItem
        v-if="newsList[2]"
        :item="newsList[2]"
      />

      <div
        class="news-vline"
        v-if="newsList[2]"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NewsItem from './NewsItem.vue'

export default {
  data () {
    return {
      currIndex: -1
    }
  },
  computed: {
    ...mapState('home', ['newsList'])
  },
  components: {
    NewsItem
  }
}
</script>

<style lang="scss" scoped>
.news-container {
  padding: 60px 0 66px;
  width: 100%;

  .hline {
    position: relative;
    width: 100%;
    border-top: 1px solid #e5e5e5;
    color: #000000;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;

    .title {
      position: absolute;
      top: -10px;
      left: 0;
      padding-right: 30px;
      background-color: #fff;
      font-weight: 400;
    }

    .readmore {
      position: absolute;
      top: -10px;
      right: 0;
      display: flex;
      align-items: center;
      padding-left: 30px;
      background-color: #fff;
      cursor: pointer;

      p {
        margin-right: 20px;
        font-weight: 200;
        font-size: 16px;
      }
    }
  }

  .news {
    display: flex;
    margin-top: 40px;

    justify-items: left;

    .news-item,
    .news-vline {
      height: 250px;
    }

    .news-vline {
      border-left: 1px solid #e5e5e5;
    }
  }
}
</style>
